<template>
  <div class="page_bg">
    <!-- 顶部卡片信息 -->
    <div class="link_top_bg">
      <div class="link_top_title flex-r-c-c">
        <van-image width="2.19rem" height="0.69rem"
                   src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/linkPage/link_top_logo.png"
        />
        | <span>{{ type? "前店":"后仓" }}业务收货确认单</span>
      </div>
      <div v-if="isOk" class="link_top_comfrim flex-r-s-c">
        <van-icon name="checked" size="0.8rem" color="rgb(7, 103, 171)" />
        <div class="link_top_comfrim_msg flex-c-s-c">
          <div class="">已确认收货，感谢您的支持。</div>
          <div class="link_top_comfrim_time">确认时间：</div>
        </div>
      </div>
      <div class="link_top_card">
        <div class="link_top_card_name">医院名称：{{ takingInfos.companyName }}</div>
        <div class="link_top_card_info flex-r-s-c">
          <div class="link_top_card_mobile">医院账号：{{ takingInfos.mobile }}</div>
          <div class="link_top_card_manager">客户经理：{{ takingInfos.managerName }}</div>
        </div>
        <div class="link_top_card_express flex-r-s-c">
          <div class="express_wuliu">
            <van-image v-if="takingInfos.expressCode === 'SF'" width="0.96rem" height="0.96rem"
                       src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/orderComfirm/SF.png" mode=""
            />
            <van-image v-else-if="takingInfos.expressCode === 'DBL'" width="0.96rem" height="0.96rem"
                       src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/orderComfirm/DBWL.png" mode=""
            />
            <van-image v-else-if="takingInfos.expressCode === 'EMS'" width="0.96rem" height="0.96rem"
                       src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/orderComfirm/EMS.png" mode=""
            />
            <van-image v-else-if="takingInfos.expressCode === 'JD'" width="0.96rem" height="0.96rem"
                       src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/orderComfirm/JD.png" mode=""
            />
            <van-image v-else-if="takingInfos.expressCode === 'YD'" width="0.96rem" height="0.96rem"
                       src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/orderComfirm/YD.png" mode=""
            />
            <van-image v-else-if="takingInfos.expressCode === 'ZTO'" width="0.96rem" height="0.96rem"
                       src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/orderComfirm/ZT.png" mode=""
            />
            <van-image v-else width="0.96rem" height="0.96rem"
                       src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/orderComfirm/NO.png" mode=""
            />
          </div>
          <div class="express_info_msg flex-c-s-c">
            <div class="">{{ takingInfos.expressName }}：{{ takingInfos.expressNo }}</div>
            <div class="express_info_time">发货时间：{{ takingInfos.expressTime }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 商品列表信息 -->
    <div class="link_pro_list">
      <div class="link_pro_list_no">
        货单号：{{ takingInfos.orderNo }}
        <van-divider />
      </div>
      <MyscrollList ref="myList01" :loading="listLoading" :list="proList" :line-type="1" @myload="proload">
        <template #default="slotProps">
          <div class="link_pro_list_items">
            <div class="items_indexNameNum flex-r-sb-c">
              <div class="items_indexName flex-r-s-c">
                <div class="items_index">{{ slotProps.index + 1 }}</div>
                <div class="items_name van-ellipsis">{{ slotProps.item.productMngName }}</div>
              </div>
              <div class="items_num">x{{ slotProps.item.realNum }}</div>
            </div>
            <!-- 前店 -->
            <div v-if="type" class="items_info">
              <van-row gutter="5px">
                <van-col :span="12">
                  <div class="items_info_msg van-ellipsis"><span style="color:#999999">品牌</span>
                    {{ slotProps.item.productMngBrand }}</div>
                </van-col>
                <van-col :span="12">
                  <div class="items_info_msg van-ellipsis"><span style="color:#999999">规格</span>
                    {{ slotProps.item.productMngSpecifications }}</div>
                </van-col>
                <van-col :span="12">
                  <div class="items_info_msg van-ellipsis"><span style="color:#999999">铺货价</span>
                    {{ slotProps.item.costPrice }}</div>
                </van-col>
                <van-col :span="12">
                  <div class="items_info_msg van-ellipsis"><span style="color:#999999">铺货小计</span>
                    {{ slotProps.item.costPriceTotal }}</div>
                </van-col>
                <van-col :span="12">
                  <div class="items_info_msg van-ellipsis"><span style="color:#999999">零售价</span>
                    {{ slotProps.item.salePrice }}</div>
                </van-col>
                <van-col :span="12">
                  <div class="items_info_msg van-ellipsis"><span style="color:#999999">零售小计</span>
                    {{ slotProps.item.salePriceTotal }}</div>
                </van-col>
              </van-row>
            </div>
            <!-- 后仓 -->
            <div v-else class="items_info">
              <van-row gutter="5px">
                <van-col :span="12">
                  <div class="items_info_msg van-ellipsis"><span style="color:#999999">品牌</span>
                    {{ slotProps.item.productMngBrand }}</div>
                </van-col>
                <van-col :span="12">
                  <div class="items_info_msg van-ellipsis"><span style="color:#999999">规格</span>
                    {{ slotProps.item.productMngSpecifications }}</div>
                </van-col>
                <van-col :span="12">
                  <div class="items_info_msg van-ellipsis"><span style="color:#999999">单价</span>
                    {{ slotProps.item.salePrice }}</div>
                </van-col>
                <van-col :span="12">
                  <div class="items_info_msg van-ellipsis"><span style="color:#999999">总额</span>
                    {{ slotProps.item.salePriceTotal }}</div>
                </van-col>
              </van-row>
            </div>
            <van-divider />
          </div>
        </template>
      </MyscrollList>
    </div>
    <!-- 底部信息 -->
    <div class="link_bottom_info flex-r-sa-c">
      <div class="link_bottom_info_msg">
        <div class="msg_num">{{ takingInfos.productCategore }}</div>
        <div class="msg_name">商品种类</div>
      </div>
      <div class="link_bottom_info_msg">
        <div class="msg_num">{{ takingInfos.productSumNum }}</div>
        <div class="msg_name">商品数量</div>
      </div>
      <div class="link_bottom_info_msg">
        <div class="msg_num">{{ takingInfos.totalMoney }}</div>
        <div class="msg_name">零售价总额</div>
      </div>
      <div v-if="isOk" class="link_bottom_info_btn" />
      <div v-else class="link_bottom_info_btn">
        <van-button color="#0767AB" round class="confirm-button" @click="subConfirm">确认收货</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import './index.scss'
import MyscrollList from '@/components/MyscrollList'
import { getOrderExpress, getStoreOrderExpressProduct, customerExpressConfirm } from '@/api/linkPage'
export default {
  components: { MyscrollList },
  data() {
    return {
      type: false,
      storeOrderExpressId: '',
      takingInfos: [],
      isOk: false,
      // 商品列表
      proSearch: { page: 1, limit: 10, storeOrderExpressId: '', storeOrderApiType: '' },
      proList: [],
      listLoading: false
    }
  },
  created() {
    this.type = parseInt(this.$route.query.storeOrderApiType) === 5 // 3:后仓 5:前店
    this.storeOrderExpressId = this.$route.query.storeOrderExpressId
    this.proSearch.storeOrderApiType = parseInt(this.$route.query.storeOrderApiType)
    this.proSearch.storeOrderExpressId = this.$route.query.storeOrderExpressId
    this.getCompanyInfo()
    this.getProList()
  },
  methods: {
    // 获取医院信息
    getCompanyInfo() {
      var parms = { storeOrderExpressId: this.storeOrderExpressId, storeOrderApiType: this.type ? 5 : 3 }
      getOrderExpress(parms).then(res => {
        this.takingInfos = res.data
        this.isOk = parseInt(res.data.confirmFlag) === 1
      })
    },
    // 商品
    getProList() {
      this.$toast.loading({ message: '加载中...', forbidClick: true, loadingType: 'spinner', duration: 0 })
      getStoreOrderExpressProduct(this.proSearch).then(res => {
        this.$toast.clear()
        this.$refs.myList01.load_loading(false)
        this.proList = this.proList.concat(res.data.list)
        if (parseInt(res.data.total) === 0) { this.$refs.myList01.load_empty('list', true) } else {
          if (res.data.list.length === 0) { this.$refs.myList01.load_finished(true) } // 数据全部加载完成
        }
      })
    },
    proload() {
      this.$refs.myList01.load_loading(true)
      this.$utils.debounce(() => {
        this.proSearch.page = this.proSearch.page + 1
        this.getProList()
        // 加载状态结束
      }, 50)()
    },
    // 确认
    subConfirm() {
      var parms = { storeOrderExpressId: this.storeOrderExpressId, storeOrderApiType: this.type ? 5 : 3 }
      customerExpressConfirm(parms).then(res => {
        this.isOk = true
      })
    }
  }
}
</script>

<style>
</style>
